var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _vm.showEmailCollection
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400px" },
              model: {
                value: _vm.showEmailCollection,
                callback: function($$v) {
                  _vm.showEmailCollection = $$v
                },
                expression: "showEmailCollection"
              }
            },
            [
              _c("UserEmailCollection", {
                on: {
                  submit: _vm.startNewAssessment,
                  close: function($event) {
                    _vm.showEmailCollection = false
                  }
                },
                model: {
                  value: _vm.collectedInformation,
                  callback: function($$v) {
                    _vm.collectedInformation = $$v
                  },
                  expression: "collectedInformation"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "px-8 pt-8", attrs: { flat: "", width: _vm.setWidth } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "6" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0 py-0",
                          attrs: { align: "center" }
                        },
                        [
                          this.$vuetify.breakpoint.mdAndUp
                            ? _c("v-img", {
                                staticClass: "ma-4",
                                attrs: {
                                  "max-width": "150px",
                                  src: require("@/assets/images/VitrueVidaLogoWithText.png")
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-img", {
                        staticClass: "ma-4",
                        attrs: {
                          "max-width": "450px",
                          contain: "",
                          src: require("@/assets/images/VitrueVidaDeskImage.png")
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "6" } },
                    [
                      _c("p", { staticClass: "text-h4 primary--text" }, [
                        _vm._v(" " + _vm._s(_vm.welcomeTitle) + " ")
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("shareableLink.summary")) + " "
                        )
                      ]),
                      _c(
                        "v-list-item",
                        [
                          _c("v-img", {
                            staticClass: "ma-5",
                            attrs: {
                              "max-width": "25px",
                              src: require("@/assets/images/emojis/pageWithCurl.png")
                            }
                          }),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-wrap" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("shareableLink.descriptions.1")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-img", {
                            staticClass: "ma-5",
                            attrs: {
                              "max-width": "25px",
                              src: require("@/assets/images/emojis/horizontalTrafficLight.png")
                            }
                          }),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-wrap" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("shareableLink.descriptions.2")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-img", {
                            staticClass: "ma-5",
                            attrs: {
                              "max-width": "25px",
                              src: require("@/assets/images/emojis/blush.png")
                            }
                          }),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-wrap" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("shareableLink.descriptions.3")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column my-8",
                      staticStyle: { height: "100%", width: "200px" }
                    },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "VueGlow",
                                  {
                                    staticStyle: {
                                      "border-radius": "28px",
                                      height: "100%",
                                      width: "100%"
                                    },
                                    attrs: {
                                      intensity: _vm.intensity(hover),
                                      elevation: _vm.getElevation(hover),
                                      mode: "hex",
                                      color: "#63a8ff"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: {
                                          height: "60px",
                                          width: "100%"
                                        },
                                        attrs: {
                                          color: "primary",
                                          "x-large": "",
                                          rounded: "",
                                          "data-cy": "startAssessment"
                                        },
                                        on: { click: _vm.startNewAssessment }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("buttons.start")))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0", attrs: { justify: "center" } },
            [
              _c("p", {
                staticClass: "text-caption font-weight-light",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("shareableLink.termsOfService"))
                }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }