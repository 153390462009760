<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-if="showEmailCollection"
      v-model="showEmailCollection"
      max-width="400px"
    >
      <UserEmailCollection
        v-model="collectedInformation"
        @submit="startNewAssessment"
        @close="showEmailCollection = false"
      />
    </v-dialog>
    <v-card class="px-8 pt-8" flat :width="setWidth">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" lg="6">
            <v-row align="center" class="my-0 py-0">
              <v-img
                v-if="this.$vuetify.breakpoint.mdAndUp"
                class="ma-4"
                max-width="150px"
                src="@/assets/images/VitrueVidaLogoWithText.png"
              ></v-img>
            </v-row>
            <v-img
              class="ma-4"
              max-width="450px"
              contain
              src="@/assets/images/VitrueVidaDeskImage.png"
            ></v-img>
          </v-col>
          <v-col cols="12" lg="6">
            <p class="text-h4 primary--text">
              {{ welcomeTitle }}
            </p>
            <p>
              {{ $t("shareableLink.summary") }}
            </p>
            <v-list-item>
              <v-img
                max-width="25px"
                class="ma-5"
                src="@/assets/images/emojis/pageWithCurl.png"
              ></v-img>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ $t("shareableLink.descriptions.1") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-img
                max-width="25px"
                class="ma-5"
                src="@/assets/images/emojis/horizontalTrafficLight.png"
              ></v-img>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ $t("shareableLink.descriptions.2") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-img
                max-width="25px"
                class="ma-5"
                src="@/assets/images/emojis/blush.png"
              ></v-img>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{
                  $t("shareableLink.descriptions.3")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <div class="d-flex flex-column my-8" style="height:100%; width:200px">
            <v-hover>
              <template v-slot="{ hover }">
                <VueGlow
                  :intensity="intensity(hover)"
                  :elevation="getElevation(hover)"
                  mode="hex"
                  color="#63a8ff"
                  style="border-radius:28px; height:100%; width:100%"
                >
                  <v-btn
                    color="primary"
                    x-large
                    rounded
                    @click="startNewAssessment"
                    data-cy="startAssessment"
                    style="height:60px; width:100%"
                    >{{ $t("buttons.start") }}</v-btn
                  >
                </VueGlow>
              </template>
            </v-hover>
          </div>
        </v-row>
      </v-card-text>
      <v-row justify="center" class="ma-0 pa-0">
        <p
          class="text-caption font-weight-light"
          v-html="$t('shareableLink.termsOfService')"
        ></p>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import { startNewShareableLinkAssessment, getTeam } from "@/customApi";
import { mapMutations } from "vuex";
import VueGlow from "vue-glow";
const UserEmailCollection = () =>
  import("@/components/common/UserEmailCollection.vue");

export default {
  components: {
    VueGlow,
    UserEmailCollection
  },
  data() {
    return {
      showEmailCollection: false,
      collectedInformation: { email: "", name: "" }
    };
  },
  async mounted() {
    const valid = await getTeam(this.$route.params.parentCompanyId).catch(
      () => {
        this.$router.push("/notfound", () => {});
      }
    );

    if (!valid) {
      this.$router.push("/notfound", () => {});
    }

    this.collectedInformation.email = this.email;
    this.collectedInformation.name = this.name;
  },
  methods: {
    ...mapMutations(["setCurrentUserTeamAndSubscriptionBasicInformation"]),
    data() {
      return {
        hover: false
      };
    },
    startNewAssessment() {
      if (!this.emailAddress && !this.collectedInformation.email) {
        this.showEmailCollection = true;
        return;
      }

      let invitation = {
        assessmentType: "Desk assessment",
        email: this.emailAddress
          ? { value: this.emailAddress }
          : { value: this.collectedInformation.email },
        name: this.name ? this.name : this.collectedInformation.name,
        tags: this.companyName ? [this.companyName] : []
      };

      startNewShareableLinkAssessment(
        invitation,
        this.$route.params.parentCompanyId
      )
        .then(assessmentID => {
          const newAssessmentUrl = "/deskassessment/share/" + assessmentID;
          parent.postMessage(
            "https://www.vitruevida.com/#" + newAssessmentUrl,
            "*"
          );
          this.$router.push(newAssessmentUrl, () => {});
        })
        .catch(err => {});
    },
    getElevation(hover) {
      if (this.disabled) {
        return 0;
      }
      return hover ? 22 : 10;
    },
    intensity(hover) {
      if (this.disabled) {
        return 0;
      }
      return hover ? 3 : 2;
    },
    capitalise(string) {
      let words = string.split(" ");

      let endString = "";
      let word = "";
      for (word of words) {
        endString += " " + word.charAt(0).toUpperCase() + word.slice(1);
      }

      return endString;
    },
    validateEmail(email) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    }
  },
  computed: {
    welcomeTitle() {
      if (this.allParametersExist) {
        return this.$t("shareableLink.welcomeTitle.teamAndName", {
          "0": this.name,
          "1": this.companyName
        });
      } else if (this.someParametersExist) {
        return this.$t("shareableLink.welcomeTitle.team", {
          "0": this.companyName
        });
      }
      return this.$t("shareableLink.welcomeTitle.generic");
    },
    allParametersExist() {
      const params = this.$route.params;
      return params.companyName && params.emailAddress && params.name;
    },
    someParametersExist() {
      const params = this.$route.params;
      return params.companyName && params.emailAddress;
    },
    companyName() {
      const params = this.$route.params;
      const string = decodeURI(params.companyName);
      return params.companyName ? this.capitalise(string) : "";
    },
    emailAddress() {
      const params = this.$route.params;
      let email = "";
      if (params.emailAddress) {
        let emailFromUrl = decodeURI(params.emailAddress);
        email = this.validateEmail(emailFromUrl) ? emailFromUrl : "";
      }
      return email;
    },
    name() {
      const params = this.$route.params;
      const string = decodeURI(params.name);
      return params.name ? this.capitalise(string) : "";
    },
    setWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "80%";
        case "lg":
          return "60%";
        case "xl":
          return "60%";
      }
      return "60%";
    }
  }
};
</script>
